<template>
  <div>
    <div v-if="rules.length === 0">
      <v-skeleton-loader
        v-for="i in 3"
        :key="i"
        max-width="100"
        type="text"
        class="mt-2"
      ></v-skeleton-loader>
    </div>
    <v-treeview
      v-else
      :items="rules"
      activatable
      open-on-click
      hoverable
      transition
      :active.sync="active"
      :open.sync="open"
      return-object
      @update:active="showDetails(active)"
    >
      <template v-slot:prepend="{ item }">
        <v-icon v-if="item.children">
          mdi-{{ icons[item.name] }}
        </v-icon>
      </template>

      <template v-slot:label="{ item }">
        <span v-if="item.children">{{ item.name }}</span>
        <span v-else class="body-2">{{ item.name }}</span>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    active: [],
    open: [],
    icons: {
      CAISSE: "cash-register",
      COMPTE: "card-account-details",
      GLOBAL: "store",
    },
  }),
  computed: {
    ...mapGetters({
      rules: "rules/formattedRules",
    }),
  },
  methods: {
    showDetails: function (data) {
      this.$root.$emit("printDetails", data[0]);
    },
  },
};
</script>

<style>
.v-treeview-node__prepend {
  min-width: 0px !important;
}
.v-treeview-node__level {
  width: 0px !important;
}
</style>
